import { defineStore } from 'pinia'

var userInfo = localStorage.getItem('userInfo');
userInfo = !userInfo || userInfo == undefined || userInfo == null ? '' : JSON.parse(userInfo);

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    token: localStorage.getItem('token') || '', // 管理员信息
    userInfo: userInfo, // 管理员信息
  }),
  actions: {
    setToken(value) {
      this.token = value;
      localStorage.setItem('token',value);
    },
    setUserInfo(value) {
      this.userInfo = value;
      localStorage.setItem('userInfo',JSON.stringify(value));
    },
    clear() {
      this.userInfo = '';
      this.token = '';
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
    }
  },
  getters: {
    getToken() {
      return this.token;
    },
    getUserInfo() {
      return this.userInfo;
    },
  },
});