import request from '@/plugins/request';

// 获取资产信息
export function getAssets (data) {
    return request({
        url: 'api/bussiness/getAssets',
        method: 'post',
        data: data
    });
}

// 获取用户列表
export function getBusinessList (data) {
    return request({
        url: 'api/bussiness/getBusinessList',
        method: 'post',
        data: data
    });
}

// 关注操作
export function toBusinessRelation (data) {
    return request({
        url: 'api/bussiness/toBusinessRelation',
        method: 'post',
        data: data
    });
}

// 获取子账号列表
export function getBusinessSonList (data) {
    return request({
        url: 'api/bussiness/getBusinessSonList',
        method: 'post',
        data: data
    });
}

// 新建、编辑子账号
export function saveSonAccount (data) {
    return request({
        url: 'api/bussiness/saveSonAccount',
        method: 'post',
        data: data
    });
}

// 新增、编辑管理员
export function saveMemberInfo (data) {
    return request({
        url: 'api/bussiness/saveMemberInfo',
        method: 'post',
        data: data
    });
}

// 充值、扣款
export function toRecharge (data) {
    return request({
        url: 'api/bussiness/toRecharge',
        method: 'post',
        data: data
    });
}

// 获取管理员列表
export function getManagerList (data) {
    return request({
        url: 'api/bussiness/getManagerList',
        method: 'post',
        data: data
    });
}

// 获取我的权限
export function getManagerAuth () {
    return request({
        url: 'api/bussiness/getManagerAuth',
        method: 'post',
    });
}

// 分配权限
export function setManagerAuth (data) {
    return request({
        url: 'api/bussiness/setManagerAuth',
        method: 'post',
        data: data
    });
}

// 新增、编辑管理员
export function saveManager (data) {
    return request({
        url: 'api/bussiness/saveManager',
        method: 'post',
        data: data
    });
}

// 获取ADS列表
export function getAdsList (data) {
    return request({
        url: 'api/bussiness/getAdsList',
        method: 'post',
        data: data
    });
}

// 改变用户状态
export function editStatus (data) {
    return request({
        url: 'api/bussiness/editStatus',
        method: 'post',
        data: data
    });
}

// 改变管理员状态
export function editManagerStatus (data) {
    return request({
        url: 'api/bussiness/editManagerStatus',
        method: 'post',
        data: data
    });
}