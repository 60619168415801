import request from '@/plugins/request';

// 获取系统全局配置
export function getSysConfig (data) {
    return request({
        url: 'api/public/getSysConfig',
        method: 'post',
    });
}

// 上传文件
export function uploadFile (data) {
    return request({
        headers:{
            "Content-Type":'multipart/form-data',
            timeout:20000,
        },
        url: 'api/public/uploadFile',
        method: 'post',
        data: data
    });
}

// 登陆
export function toLogin (data) {
    return request({
        url: 'api/login/login',
        method: 'post',
        data: data
    });
}

// 是否登陆
export function toLogout () {
    return request({
        url: 'api/login/logout',
        method: 'post',
    });
}

// 登陆
export function toRegister (data) {
    return request({
        url: 'api/login/register',
        method: 'post',
        data: data
    });
}

// 获取登陆、注册验证码
export function captcha (data) {
    return request({
        url: 'api/login/captcha',
        method: 'post',
    });
}

// 获取任务进度
export function getTaskProgress (data) {
    return request({
        url: 'api/public/getTaskProgress',
        method: 'post',
        data: data
    });
}

// 清理缓存
export function clearCache () {
    return request({
        url: 'api/login/clearCache',
        method: 'post',
    });
}

export function requestUrl (url, method = 'post', data) {
    return request({
        url: url,
        method: method,
        data: data
    });
}