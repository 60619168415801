<script setup>
    /* 引入 */
    import { ref, onBeforeMount, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { ArrowRight } from '@element-plus/icons-vue';

    const _useRouter = useRouter(); // 获取当前路由对象
    const matched = ref([]);
    const currentUrl = ref('');

    /* 生命周期 */
    onBeforeMount(() => {
        currentUrl.value = _useRouter.currentRoute.value.path;
        mergeMatched(_useRouter.currentRoute.value.matched);
        
    });

    // 创建一个计算属性来观测路由的变化
    watch(() => _useRouter.currentRoute.value, (to) => {
        currentUrl.value = to.path;
        mergeMatched(to.matched);

    });

    const mergeMatched = (route) => {
        matched.value = [];
        for (var i = 0; i < route.length; i++) {
            var item = route[i];
            if(i == 0 || item.path == '/home') {
                if(matched.value.length == 0) {
                    matched.value.push({
                        title: '首页',
                        path: '/home',
                        active: currentUrl.value == '/home' ? true : false
                    });
                }
            } else {
                matched.value.push({
                    title: item.meta.title,
                    path: i == 1 && item.children.length > 0 ? '' : item.path,
                    active: currentUrl.value == item.path ? true : false
                })
            }
        }
    }
</script>

<template>
    <el-breadcrumb :separator-icon="ArrowRight">
        <template v-for="(item, key) in matched">
            <el-breadcrumb-item v-if="!item.path" :class="active ? 'menu-active' : ''">{{item.title}}</el-breadcrumb-item>
            <el-breadcrumb-item v-else :replace="{ path: item.path }" :class="item.active ? 'menu-active' : ''">{{item.title}}</el-breadcrumb-item>
        </template>
    </el-breadcrumb>
</template>

<style scoped lang="scss">
    .menu-active :deep(.el-breadcrumb__inner){
        color: var(--el-color-black)!important;
        font-weight: bold;
    }
</style>