import { ElMessage, ElLoading, ElNotification } from 'element-plus';
import routers from '@/router';
import { ref } from 'vue';

// 弹出信息
export const ShowMsg = (type, msg, style = 'notification') => {
	let typeAuth = ['error', 'success', 'warning', 'info'];
	if(typeAuth.includes(type)) {
		if(msg == '登陆信息失效，请重新登陆') {
			return '';
		}
		if(style == 'message'){
			ElMessage({
				message: msg,
				grouping: true,
				type: type,
				showClose: true,
			});
		} else {
			ElNotification ({
				type: type,
				message: msg,
			});
		}
	}
}

// 获取今日日期
export const GetToDay = () => {
    let date = new Date();
    let year = date.getFullYear(); // 获取四位数的年份
    let month = date.getMonth() + 1; // 获取月份（注意需要加上1，因为月份从0开始计算）
    let day = date.getDate(); // 获取天数
    return `${year}-${month}-${day}`; // 打印形如"YYYY-MM-DD"的字符串
}

// 时间戳转日期格式
export const FormatDate = (timestamp, format = 'y-m-d h:i:s') => {
    var date = new Date(timestamp * 1000);

    format = format.replace('y', date.getFullYear());
    format = format.replace('m', (date.getMonth() + 1).toString().padStart(2, '0'));
    format = format.replace('d', date.getDate().toString().padStart(2, '0'));
    format = format.replace('h', date.getHours().toString().padStart(2, '0'));
    format = format.replace('i', date.getMinutes().toString().padStart(2, '0'));
    format = format.replace('s', date.getSeconds().toString().padStart(2, '0'));

    return format;
}

export const SwapTimezone = (date, zone) => {
	var regex = /UTC([+-])(?:(?:0([\d]{1}))|(1[1-2]{1})):00/
	var ret = regex.exec(zone);
	var datetime = new Date(date).getTime() / 1000;

	if(!ret || ret.length < 4) {
		return '';
	}

	if(ret[1] == '+') {
		var addTime = ret[2] ? ret[2] : ret[3];
		datetime = datetime + 3600 * addTime;
	} else {
		var addTime = ret[2] ? ret[2] : ret[3];
		datetime = datetime - 3600 * addTime;
	}

	return FormatDate(datetime);
}

// 跳转
export const Jump = (url) => {
    if(url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
        window.open(url);
    } else {
        routers.push(url);
    }
}

// 复制
export const CopyToClipboard = (text, title = '') => {
	if(!text) {
		return;
	}
	if (navigator.clipboard && window.isSecureContext) {
    	navigator.clipboard.writeText(text)
	    .then(() => {
	        ShowMsg('success', '已成功复制'+title+'到剪贴板');
	    })
	    .catch((error) => {
	        ShowMsg('error', '无法复制'+title+'到剪贴板');
	    });
	} else {
		// 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            if(document.execCommand('copy')) {
            	ShowMsg('success', '已成功复制'+title+'到剪贴板');
            } else {
            	ShowMsg('error', '无法复制'+title+'到剪贴板');
            }
            textArea.remove();
        });
        console.log('copy');
	}
}

// 转换为服务器路径
export const SwapServerUrl = (url) => {
	return import.meta.env.VITE_API_URL + '/' + url;
}

export const setFixedPannel = (refEl, bottom = 0) => {
	if(!refEl) {
		return '100px';
	}

	var headerHeight = 80 + 10;// 高度 + 外边距
	var bottomHeight = bottom + 60;// 距离底部
	var ret = `calc(100vh - ${refEl.offsetTop}px - ${headerHeight}px - ${bottomHeight}px)`;

	return ret;
}

export const webSocket = {
	ws: null,
    messages: [],
    init: function() {
    	var preWs = window.location.protocol == 'https:' ? 'wss:' : 'ws:';
    	webSocket.ws = new WebSocket(preWs + import.meta.env.VITE_WS_URL);
    	// webSocket.ws.onopen = () => {
    	// 	console.log('WebSocket connected');
    	// };

    	// webSocket.ws.onmessage = (event) => {
    	// 	try{
	    // 		const message = JSON.parse(event.data);
	    //    		messages.value.push(message);
	    //    	} catch {

	    //    	}
    	// };

    	// webSocket.ws.onerror = () => {
    	// 	console.log('WebSocket connected');
    	// };

    	// webSocket.ws.onclose = () => {
    	// 	console.log('WebSocket connected');
    	// };
    },
 	sendMessage: function(message) {
		if (webSocket.ws && webSocket.ws.readyState === WebSocket.OPEN) {
			webSocket.ws.send(typeof message == 'object' ? JSON.stringify(message) : message);
		}
    }
}