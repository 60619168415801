import './assets/style/default.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

// 加载elementUI
import ElementPlus from 'element-plus'
import * as Icons from '@element-plus/icons-vue'// 引入所有图标，并命名为 Icons
import 'element-plus/dist/index.css?v=1.0.15'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App)

// 通过遍历的方式注册所有 svg组件，会牺牲一点点性能
for (let i in Icons) {
  app.component(i, Icons[i])
}

app.use(createPinia())
app.use(router)
app.use(ElementPlus, {
  locale: zhCn,
})

app.mount('#app')
