import axios from 'axios';
import router from '@/router';
import { useUserInfoStore } from '@/stores/UserInfoStore.js';
import { reactive } from 'vue'

const apiBaseURL = import.meta.env.VITE_API_URL;
const envMode = import.meta.env.VITE_ENV;

// 创建一个 axios 实例
const service = axios.create({
    baseURL: '/',
    timeout: 120000, // 请求超时时间
    withCredentials: true, // 异步请求携带cookie
    responseType: 'json',
});

const toLogin = () => {
    var store = reactive(useUserInfoStore());
    store.clear();
    // 延时跳转，防止跳转后处于加载状态
    setTimeout(function() {router.push('/');}, 500);
}

axios.defaults.withCredentials = true;// 携带cookie

// 请求拦截器
service.interceptors.request.use(
    async config => {
        // config.baseURL = envMode == 'development' ? '/' : apiBaseURL;

        config.headers['Accept'] = '*/*';

        var Authorization = useUserInfoStore().getToken;

        if (Authorization) {
            config.headers['Authorization'] = Authorization;
        }

        return config;
    },
    error => {
        // 发送失败
        Promise.reject(error);
    }
);
// 响应拦截器
service.interceptors.response.use(
    response => {
        const code = response.data.code;
        
        switch (code) {
            case 200:
            case 201:
                return response.data;
            case 400:
                // toLogin();
                return Promise.reject(response.data.msg || { msg: '未知错误' });
                // break;
                // return Promise.reject(response.data || { msg: '未知错误' });
            case 410000:
            case 410001:
            case 410002:
                toLogin();
                // window.router.replace('/login');
                // localStorage.clear();
                // router.push('/login');
                // console.log('拦截跳转');
                break;
            default:
                return Promise.reject(response.data || { msg: '未知错误' });
                break;
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '登陆信息失效，请重新登陆';
                    toLogin();
                    break;
                case 401:
                    error.message = '无权限访问';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    // error.message = 
                    break;
            }
        }
        error.msg = error.message;
        return Promise.reject(error);
    }
);

export default service;